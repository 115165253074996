/**
 * Calculates the initial redirect path when a user is authenticated, for use in authentication success handlers or the root URL redirector.
 *
 * This function assumes it is running in the Nuxt context and the user is already authenticated (equivalent of {@link useSupabaseUser} being non-null).
 */
export default ({ consumeCookie } = { consumeCookie: false }): string => {
  const supabaseConfig = useRuntimeConfig().public.supabase;
  const { cookieRedirect } = supabaseConfig.redirectOptions;
  const { cookieName, cookieOptions } = supabaseConfig;

  let redirectPath = "/dashboard";

  // Only check for an alternate path when cookie redirects are enabled
  if (cookieRedirect) {
    const redirectCookie = useCookie(
      `${cookieName}-redirect-path`,
      // @ts-ignore The compiler is blowing up trying to type check the options passed to the composable
      cookieOptions
    );

    if (redirectCookie.value) {
      redirectPath = redirectCookie.value;
      if (consumeCookie) {
        redirectCookie.value = undefined;
      }
    }
  }

  return redirectPath;
};
